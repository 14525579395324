@import 'var';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
    font-family: 'Circular Std';
    src: url('../assets/fonts/CircularStd-Medium.eot');
    src: url('../assets/fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/CircularStd-Medium.woff2') format('woff2'),
        url('../assets/fonts/CircularStd-Medium.woff') format('woff'),
        url('../assets/fonts/CircularStd-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('../assets/fonts/CircularStd-BoldItalic.eot');
    src: url('../assets/fonts/CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/CircularStd-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/CircularStd-BoldItalic.woff') format('woff'),
        url('../assets/fonts/CircularStd-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CircularXX';
    src: url('../assets/fonts/CircularXX-Thin.eot');
    src: url('../assets/fonts/CircularXX-Thin.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/CircularXX-Thin.woff2') format('woff2'),
        url('../assets/fonts/CircularXX-Thin.woff') format('woff'),
        url('../assets/fonts/CircularXX-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std Book';
    src: url('../assets/fonts/CircularStd-BookItalic.eot');
    src: url('../assets/fonts/CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/CircularStd-BookItalic.woff2') format('woff2'),
        url('../assets/fonts/CircularStd-BookItalic.woff') format('woff'),
        url('../assets/fonts/CircularStd-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('../assets/fonts/CircularStd-Bold.eot');
    src: url('../assets/fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/CircularStd-Bold.woff2') format('woff2'),
        url('../assets/fonts/CircularStd-Bold.woff') format('woff'),
        url('../assets/fonts/CircularStd-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('../assets/fonts/CircularStd-BlackItalic.eot');
    src: url('../assets/fonts/CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/CircularStd-BlackItalic.woff2') format('woff2'),
        url('../assets/fonts/CircularStd-BlackItalic.woff') format('woff'),
        url('../assets/fonts/CircularStd-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('../assets/fonts/CircularStd-Black.eot');
    src: url('../assets/fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/CircularStd-Black.woff2') format('woff2'),
        url('../assets/fonts/CircularStd-Black.woff') format('woff'),
        url('../assets/fonts/CircularStd-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('../assets/fonts/CircularStd-MediumItalic.eot');
    src: url('../assets/fonts/CircularStd-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/CircularStd-MediumItalic.woff2') format('woff2'),
        url('../assets/fonts/CircularStd-MediumItalic.woff') format('woff'),
        url('../assets/fonts/CircularStd-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std Book';
    src: url('../assets/fonts/CircularStd-Book.eot');
    src: url('../assets/fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/CircularStd-Book.woff2') format('woff2'),
        url('../assets/fonts/CircularStd-Book.woff') format('woff'),
        url('../assets/fonts/CircularStd-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

* {
    scrollbar-width: thin;
    scrollbar-color: $primary;
}

::-webkit-scrollbar {
    width: .625rem;
    height: .625rem;
}

::-webkit-scrollbar-thumb {
    background-color: #008F6B;
    border-radius: .5rem;
    border: 1px solid transparent;
    background-clip: padding-box;
    box-shadow: 0 0 1px rgb(white, 0.5);
}

::-webkit-scrollbar-track {
    background: transparent;
}